import { ReactElement, ReactNode, useState, useContext, useEffect } from 'react'
import styles from './symptom-page.module.css'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { getSymptoms, setSymptoms } from '../../stores/symptoms/actions';
import { userContext } from './../../App';
import { 
  getUser
} from '../../stores/user/actions'
import { 
  getSubject
} from '../../stores/subject/actions'

type Props = {
  children?: ReactNode,
  getSymptoms:(token:string) => any,
  setSymptoms:(params:any) => any,
  getUser:(token:string) => any,
  getSubject(token:string, code:string):any,
  store:any,
  className?: string
}

const SymptomStep = (props: Props): ReactElement<Props> => {

  const { getSymptoms, setSymptoms, getUser, getSubject, store } = props

  const userToken = useContext(userContext);

  const [symtomsArr, setSymtomsArr] = useState([])
  const [checkedItems, setCheckedItems] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    if(store.userData["Access-Token"] === '') {
      getUser(userToken).then((res:any) => {
        getSubject(userToken, res.FirstName)
      })
    }
  }, [store])

  const symtomsDictionary:any = {
    'Back Pain': 'ألم في الظهر',
    'Blue lips or face': 'زرقة في الوجه أو الفم',
    'Congestion':  'احتقان',
    'Cough (dry)': 'كحه جافة',
    'Cough (wet)': 'كحه مع بلغم',
    'Save': 'حفظ', 
    'Uncheck All': 'الغاء الكل',
    'Fatigue': 'تعب وإرهاق',
    'Fever': 'ارتفاع في درجة الحرارة',
    'Headache': 'صداع',
    'Inability to wake or stay wake': 'عدم القدرة على المشي أو صعوبة أثناء المشي',
    'Joint Aches': 'آلام في المفاصل',
    'Loss of appetite': 'فقدان للشهية',
    'Loss of Smell':'فقدان حاسة الشم',
    'Mucus or phlegm': 'المخاط أو البلغم',
    'Muscle Pain': 'آلام في العضلات',
    'Persistent pain or pressure in the chest': 'ألم أو ضغط مستمر في الصدر',
    'Runny Nose': 'سيلان في الأنف',
    'Shortness of breath': 'صعوبة في التنفس',
    'Vomiting': 'استفراغ أو قيء',
    'None of the above':'لا شيء مما سبق'
}

  const submitHandler = (event: React.SyntheticEvent):void => { 
    event.preventDefault();

    const repliesArr = Object.keys(checkedItems).map((item:any) => {
      return {
        "symptom_text": item,
        "reply": true
      }
    })

    setSymptoms(repliesArr).then(() => navigate('/saudi-recording-1'))
  }

  const handleChange = (event:any) => {
    // updating an object instead of a Map
    setCheckedItems({...checkedItems, [event.target.name] : event.target.checked });
}

  const getSymtomsList = ():ReactNode => {
    
    if(symtomsArr.length === 0 && store.userData["Access-Token"] && store.userData["Access-Token"] !== '') {
       getSymptoms(store.userData["Access-Token"]).then((res:any) => {
        const filterArr = res.filter((item:any) => symtomsDictionary.hasOwnProperty(item.symptom_text));
        setSymtomsArr(filterArr)
        return renderSymtomsControls(filterArr)
      })
    } else {
      const arr = symtomsArr
      return renderSymtomsControls(arr)
    }
  }

  const renderSymtomsControls = (symtomsArr:Array<any>) => {

    return symtomsArr.map((item, index:number) => {
      return (
        <div className={styles.checkbox_line} key={'fragment' + index}>
          <label htmlFor={`symtoms${item.id}`}>
            <div className={styles.checkbox_wrap}>
              <input 
                className={styles.checkbox} 
                type="checkbox" 
                id={`symtoms${item.id}`} 
                name={`${item.symptom_text}`}
                checked={checkedItems[item.name]} 
                onChange={handleChange} 
                />
              </div>
              <div className={styles.symptom_text}>
                <div>{`${item.symptom_text} `}&nbsp;</div>
                { 
                symtomsDictionary[item.symptom_text] ? 
                <div>
                  {` / ${symtomsDictionary[item.symptom_text]}`}
                </div>
                : ''}
              </div>
          </label>
        </div>
      )
    })
  }

  return (
      <div className={styles.container}>
        <div className={styles.form_wrap}>
          <div className={styles.header}>
            <div className={styles.header_line}>
              Step 2 of 4
            </div>
            <div className={`${styles.header_line} ${styles.arabic_style}`}>4الخطوة 2 من</div>
          </div>
          <div className={styles.header}>
            <div className={styles.header_line}>
              Check all symptoms that you are experiencing in the last 24 hours.
            </div>
            <div className={styles.header_line}>
            هل ظهرت عليك أي أعراض جديدة في آخر 24 ساعة؟
            </div>
          </div>
          <div className={styles.header}>
            <div className={styles.header_line}>‍
              Select all that apply.
            </div>
            <div className={styles.header_line}>
              اختر كل ما ينطبق عليك.
            </div>
          </div>
          <form onSubmit={submitHandler} method="get">
            <div className={styles.checkbox_container}>
              {getSymtomsList()}
            </div>
          </form>
          <button
            type={'submit'}
            className={styles.submit}
            onClick={submitHandler}
          >
              <span className={styles.button_text}>Submit</span>
              <span className={styles.button_divider}>/</span>
              <span className={styles.button_text}>إرسال</span>
          </button>
        </div>
      </div>
  )
}


const mapStateToProps = (state:any) => {
  return {store: state}
}
const mapDispatchToProps = {
  getSymptoms, 
  setSymptoms,
  getUser,
  getSubject
}

export const SymptomPage = connect(
  mapStateToProps, mapDispatchToProps
)(SymptomStep)
