
import { getApiSettings } from './api-settings'
import { btoa } from './helpers'

import { Profile, AccountResponse } from './../../types/general';

const { apiUrl, apiAudioUrl, apikey, username, password } = getApiSettings()


const ApiService = {

  create(code:string):any { 
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("Api-Key", apikey);
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", `${code}@subject.com`);
    urlencoded.append("FirstName", code);
    urlencoded.append("LastName", code);
    urlencoded.append("Password", code);
    
    return fetch(`${apiUrl}/mob-app/qpc/signup/`, {
      method: 'POST',
      headers: headers,
      body: urlencoded,
      redirect: 'follow'
    })
      .then(response => response.json())
      .then((res) => {
        if(res.error_string === 'Email is already taken' ) {
          return res.error_string
        }
        return res.result
      })
      .catch(error => 
        console.log('error', error)
      );
  },

  login(code:string):any {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
    headers.append("Api-Key", apikey);
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("Email", `${code}@subject.com`);
    urlencoded.append("Password", code);

    return fetch(`${apiUrl}/mob-app/qpc/login/`, {
      method: 'POST',
      headers: headers,
      body: urlencoded,
      redirect: 'follow'
    })
    .then(response => response.json())
    .then(res => {
      return res.result
    })
    .catch(error => console.log('error', error));

  },

  getUser(token:string):any {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
    headers.append("Access-Token", token);

    return fetch(`${apiUrl}/mob-app/qpc/profile/`, {
      method: 'GET',
      headers: headers,  
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(res => {
        return res.result
      })
      .catch(error => console.log('error', error));
  },

  getSubjects(token:string):any {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
    headers.append("Access-Token", token);

    return fetch(`${apiUrl}/mob-app/qpc/subject/`, {
      method: 'GET',
      headers: headers,  
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        return result
      })
      .catch(error => console.log('error', error));
  },

  getSubjectById(token:string, id:string):any {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
    headers.append("Access-Token", token);

    return fetch(`${apiUrl}/mob-app/qpc/subject/${id}/`, {
      method: 'GET',
      headers: headers,  
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        return result
      })
      .catch(error => console.log('error', error));
  },

  createSubject(token:string, code:string):any {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
    headers.append("Access-Token", token);

    let formdata = new FormData();
    formdata.append("email", `${code}@subject.com`);
    formdata.append("first_name", code);
    formdata.append("last_name", code);

    return fetch(`${apiUrl}/mob-app/qpc/subject/`, {
      method: 'POST',
      headers: headers,  
      body: formdata,
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        return result
      })
      .catch(error => console.log('error', error));
  },

  uploadFileForSubject(token:string, idUser:string, idSubject:string, file:any):any {

    const headers = new Headers();
    const basic = 'Basic ' + btoa(username + ":" + password)
    headers.append("Access-Token", token);
    headers.append("Authorization", basic);

    let formdata = new FormData();
    formdata.append("UserId", idUser);
    formdata.append("audio", file, `${file.name}`);
    formdata.append("SubjectId", idSubject);

    return fetch(`${apiAudioUrl}/api/qpc/audio`, {
      method: 'POST',
      headers: headers,
      body: formdata,
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  },

  getSymptoms(token:string):any {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
    headers.append("Access-Token", token);

    return fetch(`${apiUrl}/mob-app/qpc/symptoms/`, {
      method: 'GET',
      headers: headers,  
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        return result
      })
      .catch(error => console.log('error', error));
  },

  setSymptoms(token:string, data:any):any {
    const headers = new Headers();
    headers.append("Access-Token", token);
    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
    headers.append("Content-Type", "application/json");

    return fetch(`${apiUrl}/mob-app/qpc/symptoms/reply/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => result)
      .catch(error => console.log('error', error));
  }

}

export default ApiService