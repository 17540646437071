import { Symptoms } from './interfaces'
import { 
  GET_SYMPTOMS
} from './types'
import createReducer from '../utils'

const initialState: Symptoms = { 
  "email": '',
  "first_name": '',
  "last_name": '',
  "user_type": '',
  "is_active": false,
  "full_name": '',
  "photo_url": '',
  "id": 0,
  "profile": {}
}

const symptomsReducers = createReducer(initialState)({
  [GET_SYMPTOMS]: (state:any, action:any) => {
    return {...state, ...action.payload.symptoms}
  }
})

export default symptomsReducers
