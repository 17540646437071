
interface ApiSettings {
  apiUrl: string,
  apiAudioUrl: string,
  apikey: string,
  token: string,
  username: string,
  password: string,
}

export function getApiSettings(): ApiSettings {
  if (!process.env) return {
    apiUrl: '',
    apiAudioUrl: '',
    apikey: '',
    token: '',
    username: '',
    password: '',
  }

  const {
    REACT_APP_API_URL: apiUrl = "",
    REACT_APP_API_AUDIO_URL: apiAudioUrl = "",
    REACT_APP_API_KEY: apikey = "",
    REACT_APP_API_TOKEN: token  = "",
    REACT_APP_MA_AUTH_USER: user = "",
    REACT_APP_MA_AUTH_PASS: password  = ""} = process.env

  return {
    apiUrl: apiUrl,
    apiAudioUrl: apiAudioUrl,
    apikey: apikey,
    token: token,
    username: user,
    password: password,
  }
}
