import ApiService from '../../lib/api'
import { Subject } from './interfaces'

import { 
  CREATE_SUBJECT,
  GET_SUBJECT,
  UPLOAD_FILE_FOR_SUBJECT
} from './types'

export const createSubject = (token:string, code:string) => (dispatch:any) => {
  return ApiService.createSubject(token, code)
    .then((response:Subject | string) => {

      if(response === 'Email is already taken' ) {
        return response
      }

      dispatch({
        type: CREATE_SUBJECT, 
        payload: {
          subject: response
        }
      })

      return response
    })
}

export const getSubject = (token:string, code:string) => (dispatch:any) => {
  return ApiService.getSubjects(token)
    .then((response:Array<any>) => {

      if(response.length > 0) {
       const subject = response.find((item:any) => item.email === `${code}@subject.com`);
    
        dispatch({
          type: GET_SUBJECT, 
          payload: {
            subject: subject
          }
        })
      }

      return response
    })
}

export const uploadFileForSubject = (file:any) => (dispatch:any, getState:any) => {
  const { id } = getState().subject
  const token = getState().userData["Access-Token"]
  const userId = getState().userData.UserId

  return ApiService.uploadFileForSubject(token, userId, id, file)
    .then((response:Array<any>) => {

      return response
    })
}

