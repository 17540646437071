import { ReactElement, ReactNode, useState } from 'react'
import { connect } from 'react-redux'
import { 
  createUser,
  loginUser
} from '../../stores/user/actions'
import { 
  createSubject,
  getSubject
} from '../../stores/subject/actions'
import styles from './study-id-page.module.css'
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';

type Props = {
  children?: ReactNode,
  createUser(code:string):any,
  loginUser(code:string):any,
  createSubject(token:string, code:string):any,
  getSubject(token:string, code:string):any,
  store:any,
  className?: string
}

const StudyIdStep = (props: Props): ReactElement<Props> => {

  const { createUser, createSubject, loginUser, getSubject } = props
  const [activeBtn, setActiveBtn] = useState(true)
  const [showValidateErrMsg, setShowValidateErrMsg] = useState(false)
  const [code, setCode] = useState('')
  const navigate = useNavigate();
  const cookies = new Cookies()

  const onChangeHandler = (event: React.ChangeEvent<{ value: string}>):void => { 
    setShowValidateErrMsg(false)
    if(event.target.value.length > 4) {
      return
    }
    setCode(event.target.value)
  }

  const onBlurHandler = (
    event: React.FocusEvent<HTMLInputElement>
  ) => { 
    if(code.length !== 4) { 
      setShowValidateErrMsg(true)
    }
  }

  const submitHandler = (event: React.SyntheticEvent):void => { 
    event.preventDefault();
    setActiveBtn(false)

    if(code.length !== 4) {
      setShowValidateErrMsg(true)
      return
    }

    createUser(code).then((res:any) => {
      setActiveBtn(true)
      if(res === 'Email is already taken' ) { 
        return loginUser(code).then((res:any) => {

          if(res["Access-Token"] && res["Access-Token"].length > 1) {
            const token = res["Access-Token"]
            getSubject(token, code).then(() => {
              cookies.set('adminToken', token, { path: '/' })
              navigate('/saudi-symptom-page')
            })
          }
        })
      }
      if(res["Access-Token"] && res["Access-Token"].length > 1) {
        const token = res["Access-Token"]
        createSubject(token, code).then((res:any) => {
          cookies.set('adminToken', token, { path: '/' })
          navigate('/saudi-symptom-page')
        })
      }
    }).catch(() => setActiveBtn(true))
  }

  return (
    <div className={styles.container}>
      <div className={styles.form_wrap}>
        <div className={styles.header}>
          <div className={styles.header_line}>
            Step 1 of 4
          </div>
          <div className={styles.header_line}>4 الخطوة 1 من</div>
        </div>
        <div className={styles.header}>
          <div className={styles.header_line}>
            Study ID
          </div>
          <div className={styles.header_line}>الرقم التعريفي:</div>
        </div>
        <form onSubmit={submitHandler} method="get">
          <label htmlFor="field">
            Enter the 4 digit number given to you by Al Borg staff during phone call
            <div className={styles.arabic} >يرجى إدخال الرقم التعريفي المكون من أربعة أرقام و الذي تم تزويدكم به بواسطة فريق معمل البرج خلال المكالمة التليفونية</div>
          </label>
          <input 
            type="number" 
            className="input" 
            maxLength={4}
            name="field" 
            placeholder="Digit number"
            value={code} 
            onChange={event => onChangeHandler(event)}
            onBlur={event => onBlurHandler(event)}
            required 
          />
          {showValidateErrMsg &&
          <div className={styles.err_msg}>The code should contain 4 digits, please, enter a valid code!</div>}
        </form>
        <button
          type={'submit'}
          className={styles.submit}
          onClick={submitHandler}
          disabled={!activeBtn}
        >
            <span className={styles.button_text}>Submit</span>
            <span className={styles.button_divider}>/</span>
            <span className={styles.button_text}>إرسال</span>
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state:any) => {
  return {store: state}
}
const mapDispatchToProps = {
  createUser,
  loginUser,
  createSubject,
  getSubject
}
export const StudyIdPage = connect(
  mapStateToProps, mapDispatchToProps
)(StudyIdStep)
