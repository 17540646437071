import { Profile } from './interfaces'
import { 
  LOGIN_USER,
  CREATE_USER,
  GET_USER
} from './types'
import createReducer from '../../stores/utils'

const initialState: Profile = { 
  'Access-Token': '',
  FirstName: '',
  FullName: '',
  LastName: '',
  UserAppleID: '',
  UserEmail: '',
  UserFacebookID: '',
  UserGoogleID: '',
  UserId: '',
  UserRegistrationDate: '',
  is_active: false,
  photo_url: '',
  user_type: '',
}

const customerReducers = createReducer(initialState)({
  [LOGIN_USER]: (state:any, action:any) => {
    return {...state, ...action.payload.userData}
  },
  [CREATE_USER]: (state:any, action:any) => {
    return {...state, ...action.payload.userData}
  },
  [GET_USER]: (state:any, action:any) => {
    return {...state, ...action.payload.userData}
  },
})

export default customerReducers
