import { Subject } from './interfaces'
import { 
  CREATE_SUBJECT,
  GET_SUBJECT,
  UPLOAD_FILE_FOR_SUBJECT
} from './types'
import createReducer from '../utils'

const initialState: Subject = { 
  "email": '',
  "first_name": '',
  "last_name": '',
  "user_type": '',
  "is_active": false,
  "full_name": '',
  "photo_url": '',
  "id": 0,
  "profile": {}
}

const subjectReducers = createReducer(initialState)({
  [CREATE_SUBJECT]: (state:any, action:any) => {
    return {...state, ...action.payload.subject}
  },
  [GET_SUBJECT]: (state:any, action:any) => {
    return {...state, ...action.payload.subject}
  },
  [UPLOAD_FILE_FOR_SUBJECT]: (state:any, action:any) => {
    return {...state, ...action.payload.subject}
  }
})

export default subjectReducers
