import ApiService from '../../lib/api'
import { Symptoms } from './interfaces'

import { 
  SET_SYMPTOMS,
} from './types'


export const getSymptoms = (token:string) => (dispatch:any, getState:any)=> {
  return ApiService.getSymptoms(token)
    .then((response:Array<any>) => {

      if(response.length > 0) {
      
        dispatch({
          type: SET_SYMPTOMS, 
          payload: {
            symptoms: response
          }
        })
      }

      return response
    })
}

export const setSymptoms = (params:any) => (dispatch:any, getState:any)=> {
  const { id } = getState().subject
  const token = getState().userData["Access-Token"]
  const data = {
      "qpc_subject": id,
      "replies": params
  }

  return ApiService.setSymptoms(token, data)
    .then((response:Array<any>) => {

        dispatch({
          type: SET_SYMPTOMS, 
          payload: {
            symptoms: response
          }
        })

      return response
    })
}



