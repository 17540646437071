import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import userReducers from './user'
import subjectReducers from './subject';
import symptomsReducers from './symptoms';

const rootReducer = combineReducers({
  subject: subjectReducers,
  symptoms: symptomsReducers,
  userData: userReducers
})

export default createStore(rootReducer, applyMiddleware(thunkMiddleware))
