import { ReactElement, ReactNode, useState } from 'react'
import styles from './consent-modal.module.css'
import logoImg from './../../assets/modal-logo.png';

type Props = {
  children?: ReactNode,
  className?: string,
  togglePopup(state:boolean): void
}

const ConsentModal = (props: Props): ReactElement<Props, any> => {
  const { togglePopup } = props
  const [privaceTermState, setPrivaceTermState] = useState(false);
  const [activeBtn, setActiveBtn] = useState(true)
  const [errMsg, setErrMsg] = useState(false)

  const submitHandler = (event: React.SyntheticEvent):void => { 
    event.preventDefault();

    if(privaceTermState) {
      togglePopup(false)
    } else {
      setErrMsg(true)
    }

  }

  const handleChange = (event:any) => {
    // updating an object instead of a Map
    setPrivaceTermState(event.target.checked)

    if(event.target.checked) {
      setErrMsg(false)
    }
}

  return (
    <div
    className={styles.popup}>
    <div className={styles.popup_inner}>
      <div className={styles.popup_logo}>
        <img src={logoImg} alt="logo" />
      </div>
      <h3 className={styles.title}>Informed Consent Form</h3>
      <div className={styles.popup_content}>
      <div className={styles.popup_term}>
        <div className={styles.popup_term_text}>
          <div>Acknowledgment of approval to participate in a research study under the supervision of Al-Borg Laboratories.</div>
          <div><br /> </div>
          <div>Part One:</div>
          <div>Study title: The use of artificial intelligence techniques and mobile applications in determining the acoustic spectral patterns of the infectious disease Covid-19</div>
          <div>Principal researcher: Dr. Sherif Idriss</div>
          <div>Address: Al Borg Laboratories - Research and Development Department - Headquarters</div>
          <div>Phone: 920004422</div>
          <div><br /> </div>
          <div><br /> </div>
          <div>&bull; A member of the research team will explain the contents of this study and the extent of benefit from it if you participate in it.</div>
          <div>&bull; This acknowledgment describes the study's procedures, and how the information is kept confidential.</div>
          <div>&bull; Please take the time to ask questions, and decide whether or not to participate.</div>
          <div>&bull; This process is called approval after insight.</div>
          <div>&bull; If you decide to participate in this study, you will be required to sign this declaration</div>
          <div>&bull; The pronoun (you) in this declaration refers to you (or to your relative under study) as appropriate.</div>
          <div><br /> </div>
          <div>Part Two: Information Sheet</div>
          <div>General information about research projects</div>
          <div>Research studies generally aim to obtain applied new knowledge that may help in solving daily or societal problems in the future.</div>
          <div>What is the purpose of this study?</div>
          <div>The purpose of this study is to collect audio samples for the purpose of a rapid initial survey of suspected COVID-19 patients. We aspire to apply experimental machine learning, digital processing, and artificial intelligence techniques to analyze this audio data. At this point, we are collecting information to develop new, rapid techniques to detect patterns and markers in audio data and their relevance to COVID-19 infection.</div>
          <div>Study procedures</div>
          <div>Participation in this study includes doing a PCR assay for COVID-19 as well as using the Sonaphi mobile application on your personal device.</div>
          <div>When you provide your consent to participate in the study, you will be asked to:</div>
          <div>Register an account: Once you have provided your consent to participate in the study, all participants will complete the online registration process through the mobile application and create a user account. It will include entering your email address and other general information about yourself.</div>
          <div><br /> </div>
          <div>Fill out a Personal Health Questionnaire: You will be provided with questionnaires about demographics, health history and diagnoses. Your answers to these questions will help us find patterns in underlying conditions for certain diagnoses. Some of the questions may be of a personal nature. You can leave it blank if you do not wish to answer.</div>
          <div>Provision of audio recordings: You will be required to use the mobile app to make audio recordings of you speaking naturally and follow the prompts of the app.</div>
          <div><br /> </div>
          <div>Your participation in this study will only take some time the first time.</div>
          <div>After completing the survey, we may, from time to time, send you notifications to your phone or email that may require you to answer some inquiries or request more audio samples. You can also use the notification settings on your device for the mobile app to control or turn off notifications.</div>
          <div>Target group to participate in this study?</div>
          <div>To be / be over the age of 18 at the time of participation, and not be / be under the influence of treatment or surgery for any of the diseases affecting the vocal cords, esophagus and pharynx.</div>
          <div>Possible benefits of participating in this study?</div>
          <div>This study is designed for the benefit of society by acquiring new technologies to examine and create methods for early detection of the possibility of infection with Covid-19 disease. In a simple, fast, inexpensive, effective and anywhere, and there are some incentives for the participants in this study.</div>
          <div><br /> </div>
          <div>What are the possible side effects, risks, or harms of participating in this study?</div>
          <div>There are none</div>
          <div>What is the expected result of using the application?</div>
          <div>One of the two outcomes is either negative or non-negative. It will be compared to the results of the Polymerase Chain Reaction (PCR) test for your COVID-19 virus to ensure that the result is correct.</div>
          <div><br /> </div>
          <div>How will my personal information be kept confidential?</div>
          <div>All information you provide, including your name, medical history and other personal information, will be kept confidential by us, unless disclosure is required by law, and we will share audio samples and health history only without personal information with a second party for analysis to find out the possibility of infection from Your name and email address will not be shared with any party. These results can also be used in scientific publication and presented to the scientific community at meetings and in scientific journals while maintaining your anonymity, and the results can be shared with regulatory authorities around the world including the Food and Drug Administration or the Scientific Research Ethics Unit to support approval of the analysis tool Sonaphi audiobook as a means of pre-screening for COVID-19.</div>
          <div><br /> </div>
          <div>Study site:</div>
          <div>Al Borg Laboratories - Kingdom of Saudi Arabia.</div>
          <div><br /> </div>
          <div>Participation in this voluntary study:</div>
          <div>Your participation in this study is completely voluntary and you have the right to refrain from answering any of the questions that you do not want to answer. You can also terminate your participation in this study at any time without the need to provide reasons and your decision not to participate in or withdraw from the study will not affect in any way the Conditions on your relationship with Al-Borg Medical Laboratories or any other organization associated with this research project while maintaining the benefits obtained during your participation and upon withdrawal, all data associated with you and collected will be destroyed whenever possible. .</div>
          <div><br /> </div>
          <div>What if you have questions about this study?</div>
          <div>You have the right to ask any questions you may have about this study, complaints, comments or concerns about the research. You may contact the Research and Development Department at Alborg Diagnostics directly on the e-mail: <u>Research@alborgdx.com</u></div>
          <div>Or through the Scientific Research Ethics Committee at Al-Borg Laboratories directly on the e-mail: <u><a href="mailto:irb@alborgdx.com">irb@alborgdx.com</a></u></div>
          <div><br /> </div>
        
        <div className={styles.popup_term_arabic}>
          <div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>نموذج
            الموافقة المستنيرة</b></div></div></span></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">إقرار
            بالموافقة على
            المشاركة في
            دراسة بحثية
            تحت اشراف مختبرات
            البرج</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><u><div ><div  aria-label="font-size: 12pt"><b>الجزء
            الأول</b></div></div></u></span></div><u><div ><div  aria-label="font-size: 12pt"><b>:</b></div></div></u></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">عنوان
            الدراسة </div></div></span></div><div ><div  aria-label="font-size: 12pt">:
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">استخدام
            تقنيات الذكاء
            الاصطناعي
            وتطبيقات الجوال
            في تحديد أنماط
            طيفية صوتية
            لمرض كوفيد</div></div></span></div><div ><div  aria-label="font-size: 12pt">-19</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">المعدي</div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">الباحث
            الرئيس</div></div></span></div><div ><div  aria-label="font-size: 12pt">:
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">د</div></div></span></div><div ><div  aria-label="font-size: 12pt">.
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">شريف
            ادريس</div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">العنوان</div></div></span></div><div ><div  aria-label="font-size: 12pt">:
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">مختبرات
            البرج</div></div></span></div><div ><div  aria-label="font-size: 12pt">-
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">قسم
            الأبحاث والتطوير</div></div></span></div><div ><div  aria-label="font-size: 12pt">-</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">المركز
            الرئيسي</div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">الهاتف
            </div></div></span></div><div ><div  aria-label="font-size: 12pt">:
            0593662384920004422</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%">&nbsp;<div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">التاريخ</div></div></span></div><div ><div  aria-label="font-size: 12pt">:</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <ul>
              <li><div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">سوف
              يقوم أحد أعضاء
              الفريق البحثى
              بشرح محتويات
              هذه الدراسة
              ومدى الاستفادة
              منها في حال
              مشاركتك فيها</div></div></span></div><div ><div  aria-label="font-size: 12pt">.
              </div></div>
              </div>
              </li><li><div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">يصف
              هذا الإقرار
              إجراءات الدراسة،
              وكيفية الحفاظ
              على سرية المعلومات</div></div></span></div><div ><div  aria-label="font-size: 12pt">.
              </div></div>
              </div>
              </li><li><div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">الرجاء
              أخذ الوقت الكافي
              في طرح الأسئلة،
              واتخاذ قرار
              المشاركة من
              عدمه</div></div></span></div><div ><div  aria-label="font-size: 12pt">.
              </div></div>
              </div>
              </li><li><div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">تسمى
              هذه العملية
              الموافقة بعد
              التبصير</div></div></span></div><div ><div  aria-label="font-size: 12pt">.
              </div></div>
              </div>
              </li><li><div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">إذا
              قررت المشاركة
              في هذه الدراسة،
              سيتطلب منك
              التوقيع على
              هذا الإقرار
              </div></div></span></div>
              </div>
              </li><li><div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">ويشير
              الضمير </div></div></span></div><div ><div  aria-label="font-size: 12pt">(</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">أنت</div></div></span></div><div ><div  aria-label="font-size: 12pt">)
              </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">في
              هذا الإقرار
              إليك </div></div></span></div><div ><div  aria-label="font-size: 12pt">(</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">أو
              إلى قريبك موضع
              الدراسة</div></div></span></div><div ><div  aria-label="font-size: 12pt">)
              </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">حسب
              الاقتضاء</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
              </li><li><div aria-label="margin-bottom: 0.11in; line-height: 108%"></div>
            </li></ul>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%">&nbsp;<div ><span lang="ar-SA"><u><div ><div  aria-label="font-size: 12pt"><b>الجزء
            الثاني</b></div></div></u></span></div><u><div ><div  aria-label="font-size: 12pt"><b>:
            </b></div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>ورقة
            المعلومات</b></div></div></span></div></u></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>معلومات
            عامة حول المشاريع
            البحثية</b></div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">تهدف
            الدراسات البحثية
            بوجه عام إلى
            الحصول على
            معلومات معرفية
            &nbsp;جديدة تطبيقية
            قد تساعد في حل
            المشكلات اليومية
            او المجتمعية
            في المستقبل</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>ما
            هو الغرض من هذه
            الدراسة</b></div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">الغرض
            من هذه الدراسة
            هو جمع عينات
            صوتية بغرض
            المسح المبدئي
            السريع للمشتبه
            في أصابتهم
            بفيروس كوفيد</div></div></span></div><div ><div  aria-label="font-size: 12pt">-19.
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">حيث&nbsp;&nbsp;نطمح
            إلى تطبيق تقنيات
            التعلم الآلي
            التجريبية
            والمعالجة
            الرقمية والذكاء
            الاصطناعي
            لتحليل هذه
            البيانات الصوتية</div></div></span></div><div ><div  aria-label="font-size: 12pt">.&nbsp;</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">في
            هذه المرحلة،
            نقوم بجمع المعلومات
            لتطوير تقنيات
            جديدة سريعة
            للكشف عن الأنماط
            والعلامات في
            البيانات الصوتية
            ومدى ارتباطها
            بالإصابة ةبفيروس
            كوفيد</div></div></span></div><div ><div  aria-label="font-size: 12pt">-19.
            </div></div>
            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div color="#000000"><u><div ><div  aria-label="font-size: 12pt"><b>إجراءات
            الدراسة</b></div></div></u></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">تتضمن
            المشاركة في
            هذه الدراسة
            عمل فحص تفاعل
            البوليميراز
            التسلسلي لفيروس
            كوفيد</div></div></span></div><div ><div  aria-label="font-size: 12pt">-19
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">وبالإضافة
            إلى ذلك </div></div><div ><div  aria-label="font-size: 12pt">استخدام
            تطبيق الهاتف
            المحمول الخاص
            بشركة </div></div></span></div><div ><div  aria-label="font-size: 12pt">Sonaphi</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">على
            جهازك الشخصي</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">عند
            تقديم موافقتك
            على المشاركة
            في الدراسة،
            سيطلب منك</div></div></span></div><div ><div  aria-label="font-size: 12pt">:</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>تسجيل
            حساب </b></div></div></span></div><div ><div  aria-label="font-size: 12pt"><b>:</b></div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">بمجرد
            تقديم موافقتك
            على المشاركة
            في الدراسة،
            سيقوم جميع
            المشاركين
            بإكمال عملية
            التسجيل الإلكتروني
            من خلال تطبيق
            الجوال وإنشاء
            حساب مستخدم</div></div></span></div><div ><div  aria-label="font-size: 12pt">.
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">وسوف
            تشمل إدخال
            عنوان البريد
            الإلكتروني
            الخاص بك وغيرها
            من المعلومات
            العامة عن نفسك</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>ملء
            استبيان الصحة
            الشخصية</b></div></div></span></div><div ><div  aria-label="font-size: 12pt">:
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">سيتم
            تزويدك باستبيانات
            حول التركيبة
            السكانية والتاريخ
            الصحي والتشخيصات</div></div></span></div><div ><div  aria-label="font-size: 12pt">.&nbsp;</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">ستساعدنا
            إجاباتك على
            هذه الأسئلة
            في العثور على
            أنماط في الحالات
            الأساسية لتشخيصات
            معينة</div></div></span></div><div ><div  aria-label="font-size: 12pt">.&nbsp;</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">قد
            تكون بعض الأسئلة
            ذات طبيعة
            شخصية</div></div></span></div><div ><div  aria-label="font-size: 12pt">.&nbsp;</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">يمكنك
            تركها فارغة
            ان كنت لا ترغب
            في الإجابة</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>توفير
            التسجيلات
            الصوتية</b></div></div></span></div><div ><div  aria-label="font-size: 12pt">:
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">سيطلب
            منك استخدام
            تطبيق الجوال
            لإجراء تسجيلات
            صوتية لك وأنت
            تتحدث بشكل
            طبيعي وتتبع
            متطالبات التطبيق</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>سوف
            تستغرق مشاركتك
            في هذه الدراسة
            بعض الوقت في
            اول مرة فقط</b></div></div></span></div><div ><div  aria-label="font-size: 12pt"><b>.</b></div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">بعد
            إكمال الدراسة،
            قد نرسل لك، من
            وقت لآخر إشعارات
            إلى هاتفك أو
            بريدك الإلكتروني
            قد تتطلب منك
            الإجابة عن بعض
            الاستفسارات
            أو طلب المزيد
            من العينات
            الصوتية كما
            يمكنك استخدام
            إعدادات الإعلام
            على جهازك لتطبيق
            الجوال للتحكم
            في الإشعارات
            أو إيقاف تشغيلها</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>الفئة
            المستهدفة
            للمشاركة في
            هذه الدراسة؟</b></div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">أن
            تكون</div></div></span></div><div ><div  aria-label="font-size: 12pt">/</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">تكوني
            فوق سن </div></div></span></div><div ><div  aria-label="font-size: 12pt">18
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">سنة
            في وقت المشاركة،
            وألا تكون</div></div></span></div><div ><div  aria-label="font-size: 12pt">/</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">تكوني
            تحت تأثير العلاج
            او الجراحة لأحد
            الامراض التي
            تصيب الأحبال
            الصوتية والمريء
            والبلعوم</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>الفوائد
            المحتملة من
            الاشتراك في
            هذه الدراسة؟</b></div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">تم
            تصميم هذه الدراسة
            لصالح المجتمع
            من خلال اكتساب
            تقنيات جديدة
            لفحص وخلق طرق
            للكشف المبكر
            عن احتمالية
            الإصابة بمرض
            كوفيد </div></div></span></div><div ><div  aria-label="font-size: 12pt">-19.</div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">بصورة
            بسيطة وسريعة
            وغير مكلفة
            وفعالة وفي أي
            مكان، كما ان
            هناك بعض الحوافز
            للمشاركين في
            هذه الدراسة</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>ما
            هي الآثار الجانبية
            أو المخاطر أو
            الأضرار التي
            يمكن حدوثها
            جراء مشاركتي
            في هذه الدراسة؟</b></div></div></span></div><div ><div  aria-label="font-size: 12pt"><b>:</b></div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">لا
            يوجد</div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>ما
            هي النتيجة
            المتوقعة من
            استخدام التطبيق؟</b></div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">احدى
            النتيجتين إما
            سلبية أو غير
            سلبية</div></div></span></div><div ><div  aria-label="font-size: 12pt">.
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">وسيتم
            مقارنتها بنتائج
            فحص تفاعل البلمرة
            المتعدد التسلسلي
            </div></div></span></div><div ><div  aria-label="font-size: 12pt">(PCR)
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">لفيروس
            كوفيد</div></div></span></div><div ><div  aria-label="font-size: 12pt">-19
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">الخاص
            بك </div></div><div ><div  aria-label="font-size: 12pt">للتأكد
            من صحة النتيجة</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>كيف
            سيتم الحفاظ
            على سرية معلوماتي
            الشخصية؟  </b></div></div></span></div>
            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">جميع
            المعلومات التي
            تقدمها، بما
            في ذلك اسمك
            وتاريخك الطبي
            والمعلومات
            الشخصية الأخرى،
            ستبقى سرية من
            قبلنا، ما لم
            يكن الكشف عنها
            مطلوباً بموجب
            القانون، كما
            سنقوم بمشاركة
            العينات الصوتية
            والتاريخ الصحي
            فقط بدون المعلومات
            الشخصية مع طرف
            ثاني من أجل
            تحليلها لمعرفة
            احتمالية الإصابة
            من عدمه ولن تتم
            مشاركة اسمك
            وعنوان بريدك
            الإلكتروني
            مع أي طرف</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div><div ><div  aria-label="font-size: 12pt">
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">كما
            يمكن استخدام
            هذه النتائج
            في النشر العلمي
            وتقديمها إلى
            الأوساط العلمية
            في الاجتماعات
            وفي المجلات
            العلمية مع
            الحفاظ على سرية
            هويتك، كما يمكن
            مشاركة النتائج
            مع السلطات
            التنظيمية في
            جميع أنحاء
            العالم بما في
            ذلك إدارة الغذاء
            والدواء أو وحدة
            أخلاقيات البحث
            العلمي لدعم
            الموافقة على
            أداة التحليل
            الصوتي الخاصة
            ب </div></div></span></div><div ><div  aria-label="font-size: 12pt">Sonaphi</div></div><div ><div  aria-label="font-size: 12pt">
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">كوسيلة
            لفحص المبدئي
            لمرض كوفيد </div></div></span></div><div ><div  aria-label="font-size: 12pt">-19.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>موقع
            الدراسة</b></div></div></span></div><div ><div  aria-label="font-size: 12pt"><b>:</b></div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">مختبرات
            البرج</div></div></span></div><div ><div  aria-label="font-size: 12pt">-
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">المملكة
            العربية السعودية</div></div></span></div><div ><div  aria-label="font-size: 12pt">.</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>المشاركة
            في هذه الدراسة
            الطوعية</b></div></div></span></div><div ><div  aria-label="font-size: 12pt"><b>:</b></div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">مشاركتك
            في هذه الدراسة
            تطوعية تماماً
            ولك الحق في
            الامتناع عن
            الإجابة على
            أي سؤال من الأسئلة
            التي لا تريد
            الإجابة عليها
            كما</div></div><div ><div  aria-label="font-size: 12pt">
            </div></div><div ><div  aria-label="font-size: 12pt">يمكنك
            إنهاء مشاركتك
            في هذه الدراسة
            في أي وقت دون
            الحاجة إلى
            تقديم أسباب
            وقرارك بعدم
            المشاركة في
            الدراسة أو
            الانسحاب منها
            لن يؤثر بأي حال
            من الأحوال على
            علاقتك مع مختبرات
            البرج الطبية
            أو أي منظمة
            أخرى مرتبطة
            بهذا المشروع
            البحثي مع حفاظك
            على المزايا
            التي تم الحصول
            عليها اثناء
            مشاركتك وعند
            الانسحاب، سيتم
            تدمير جميع
            البيانات المرتبطة
            بك والتي تم
            جمعها كلما أمكن
            ذلك</div></div></span></div><div ><div  aria-label="font-size: 12pt">.
            .</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt"><b>ماذا
            لو كان لديك
            أسئلة حول هذه
            الدراسة؟</b></div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">لديك
            الحق في طرح أي
            أسئلة قد تكون
            لديك حول هذه
            الدراسة، أو
            الشكاوى أو
            الملاحظات أو
            المخاوف من
            البحث، فيمكنك
            التواصل مع قسم
            الابحاث والتطوير
            في مختبرات
            البرج الطبية
            على البريد
            الالكتروني
            التالي </div></div></span></div><div ><div  aria-label="font-size: 12pt">:
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">الباحث
            الرئيس عن طريق
            هذا البريد
            الالكتروني
            </div></div></span></div><div color="#0563c1"><u><a href="mailto:Research@alborgdx.com"><div ><div  aria-label="font-size: 12pt">Researchs.ederis@alborgdx.com</div></div></a></u></div><div ><div  aria-label="font-size: 12pt">
            </div></div>
            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">او
            من خلال لجنة
            أخلاقيات البحث
            العلمي بمختبرات
            البرج مباشرة
            على البريد
            الالكتروني</div></div><div ><div  aria-label="font-size: 12pt">
            </div></div></span></div><div color="#0563c1"><u><a href="mailto:irb@alborgdx.com"><div ><div  aria-label="font-size: 12pt">irb@alborgdx.com</div></div></a></u></div><div ><div  aria-label="font-size: 12pt">
            </div></div>
            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">الباحث
            الرئيس</div></div></span></div><div ><div  aria-label="font-size: 12pt">:
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">د</div></div></span></div><div ><div  aria-label="font-size: 12pt">.
            </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">شريف
            ادريس</div></div></span></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">التوقيع</div></div></span></div><div ><div  aria-label="font-size: 12pt">:
                                                                  </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">التاريخ</div></div></span></div><div ><div  aria-label="font-size: 12pt">:</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">الشاهد</div></div></span></div><div ><div  aria-label="font-size: 12pt">:</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">التوقيع</div></div></span></div><div ><div  aria-label="font-size: 12pt">:
                                                                  </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">التاريخ</div></div></span></div><div ><div  aria-label="font-size: 12pt">:</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">المشارك
            أو من ينوب عنه</div></div></span></div><div ><div  aria-label="font-size: 12pt">:</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">التوقيع</div></div></span></div><div ><div  aria-label="font-size: 12pt">:
                                                                  </div></div><div ><span lang="ar-SA"><div ><div  aria-label="font-size: 12pt">التاريخ</div></div></span></div><div ><div  aria-label="font-size: 12pt">:</div></div></div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%">&nbsp;</div>
            <div aria-label="margin-bottom: 0.11in; line-height: 108%"><br />
            <br />

            </div>
        </div>
        </div>
      </div>
      <form onSubmit={submitHandler} method="get">
          <label htmlFor="field">
            <input 
              className={styles.checkbox} 
              type="checkbox" 
              id={`terms`} 
              name={`privace_terms`}
              checked={privaceTermState} 
              onChange={handleChange} 
            />
              <div className={styles.box_text}>
                <span className={styles.text}>I agree</span>
                <span className={styles.divider}>/</span>
                <span className={styles.text}>أوافق</span>
              </div>
          </label>
          {errMsg && 
          <div className={`${styles.box_text} ${styles.err_msg}`}>
            <span className={styles.text}>Please check the box (I agree) to continue</span>
            <span className={styles.divider}>/</span>
            <span className={styles.text}>ضع علامة على مربع (أوافق) للمتابعة</span>
          </div>}
        </form>

      </div>
      <div className={styles.popup_footer}>
        <button
          disabled={!activeBtn}
            type={'submit'}
            className={styles.popup_btn}
            onClick={submitHandler}
          >
              <span className={styles.text}>Submit</span>
              <span className={styles.divider}>/</span>
              <span className={styles.text}>إرسال</span>
          </button>
      </div>
    </div>
  </div>
  )
}

export default ConsentModal