import { ReactElement, ReactNode, useState, useEffect, useRef, useContext } from 'react'
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import { 
  uploadFileForSubject
} from '../../stores/subject/actions'
import { connect } from 'react-redux'
import styles from './recording-page.module.css'
import microphoneImage from './../../assets/microphone.png';
import { useNavigate } from "react-router-dom";
import { userContext } from './../../App';
import { 
  getUser
} from '../../stores/user/actions'
import { 
  getSubject
} from '../../stores/subject/actions'

type Props = {
  children?: ReactNode,
  type?: string,
  uploadFileForSubject(file:any):any,
  className?: string,
  getUser:(token:string) => any,
  getSubject(token:string, code:string):any,
  store:any,
}

const RecordingStep = (props: Props): ReactElement<Props> => {

  const { uploadFileForSubject, getUser, getSubject, store } = props
  const [activeBtn, setActiveBtn] = useState(true)

  const [stream, setStream] = useState<any>(null);
  const [blob, setBlob] = useState<any>(null);
  const refAudio = useRef<any>(null);
  const recorderRef = useRef<any>(null);

  const [ seconds, setSeconds ] = useState(30);
  const [ timerActive, setTimerActive ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const navigate = useNavigate();

  const userToken = useContext(userContext);

  useEffect(() => {
    if(store.userData["Access-Token"] === '') {
      getUser(userToken).then((res:any) => {
        getSubject(userToken, res.FirstName)
      })
    }
  }, [store])

  useEffect(() => {
    if (seconds > 0 && timerActive) {
      setTimeout(setSeconds, 1000, seconds - 1);
    } else {
      setTimerActive(false);
      handleStop();
    }
  }, [seconds,timerActive]);

  const handleRecording = async () => {
    setTimerActive(true)
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true
    });
    
    setStream(mediaStream);
    recorderRef.current = new RecordRTC(mediaStream, { type: 'audio', mimeType: 'audio/wav', recorderType: StereoAudioRecorder });
    recorderRef.current.startRecording();
  };

  const handleStop = () => {
    if(seconds !== 0) {
      return
    }
    recorderRef.current.stopRecording(() => {
      setBlob(recorderRef.current.getBlob());
    });
  };


  useEffect(() => {
    if (!refAudio.current) {
      return;
    }

    // refAudio.current.srcObject = stream;
  }, [stream, refAudio]);

  const getFileName = (fileExtension:string) => {
      var d = new Date();
      var year = d.getUTCFullYear();
      var month = d.getUTCMonth();
      var date = d.getUTCDate();
      return 'Record-' + year + month + date + '.' + fileExtension;
  }

  const submitHandler = (event: React.SyntheticEvent):void => { 
    event.preventDefault();

    if(seconds !== 0) {
      return
    }

    setActiveBtn(false)
    setLoading(true)

    const fileName = getFileName('wav');
    const fileObject = new File([blob], fileName, {
        type: 'audio/wav'
    });

    console.log('fileObject', fileObject)

    uploadFileForSubject(fileObject).then(() => {
      setBlob(null)
      setSeconds(30)
      setActiveBtn(true)
      setLoading(false)
      if(props.type === 'talk') {
        navigate('/saudi-recording-thank-you-page')
      } else {
        navigate('/saudi-recording-2')
      }
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.form_wrap}>
      {props.type === 'talk' ?
        <>
          <div className={styles.header}>
            <div className={styles.header_line}>
              Step 4 of 4
            </div>
            <div className={styles.header_line}>
              الخطوة 4 من 4
            </div>
          </div>
        </>
        : 
        <>
          <div className={styles.header}>
            <div className={styles.header_line}>
              Step 3 of 4
            </div>
            <div className={styles.header_line}>
            الخطوة 3 من 4
            </div>
          </div>
        </>
      }   
        <div className={styles.header}>
          {props.type === 'talk' ?
            <>
              <div className={styles.header_line}>
                Instructions for the second voice recording.
              </div>
              <div className={styles.header_line}>
              الإرشادات الخاصة بالتسجيل الصوتي الثاني.
              </div>
            </>
            :
            <>
              <div className={styles.header_line}>
                Instructions for the first 30-second voice recording.
              </div>
              <div className={styles.header_line}>
              الإرشادات الخاصة بالتسجيل الصوتي الأول لمدة ثلاثين ثانية
              </div>
            </>
          }
        </div>
        <form onSubmit={submitHandler} method="get">
          <label htmlFor="field">
          {props.type === 'talk' ?
            <>
              <ol className={styles.list}>
                <li>Click the microphone icon.
                  <div className={styles.arabic_text}>. انقر فوق رمز الميكروفون</div>
                </li>
                <li>Talk for 30 seconds about anything you wish.
                  <p>For example: talk about your daily routine; describe the room you are in; talk about your favourite holiday, etc <br/>
                    (Speak in a normal voice without too many pauses)
                  </p>
                    <div className={styles.arabic_text}>تحدث لمدة ثلاثين ثانية عن أي شئ ترغب بالحديث عنه
                      <p>
على سبيل المثال: تحدث عن روتينك اليومي، أو قم بوصف الغرفة التي توجد بها، أو تحدث عن أجازتك المفضلة، إلخ.
(تحدث بصوت طبيعي بدون فترات صمت طويلة)
                      </p>
                    </div>
                </li>
                <li>Click the submit button
                  <div className={styles.arabic_text}>
                  انقر على زر إرسال
                  </div>
                </li>
              </ol>
            </>
            :
            <>
              <ol className={styles.list}>
                <li>Click the microphone icon.
                  <div className={styles.arabic_text}>. انقر فوق رمز الميكروفون</div>
                </li>
                <li>Cough twice
                  <div className={styles.arabic_text}>اسعل مرتين</div>
                </li>
                <li>Start counting numbers from 0 on up.  Do this for thirty seconds (The timer will tell you when to stop)
                <div className={styles.arabic_text}>قم بعد الأرقام تصاعديا بدءا من الرقم صفر، افعل ذلك لمدة ثلاثين ثانية (المؤقت سيوضح لك متى تتوقف)</div>
                </li>
              </ol>
            </>
          }
          </label>
        </form>
        <div className={styles.btn_wrap}>
          <button className={styles.start_btn} onClick={handleRecording}>
            <img src={microphoneImage} alt="microphone" />
            start
          </button>
        </div>
        { seconds !== 0 ?
          <div className={styles.timer_wrap}>
              00:{String(seconds).length === 1 ? '0' + String(seconds) : String(seconds)}
          </div>
          : ''
        }
          {blob && (
            <div className={styles.submit_btn_wrap}>
              <button
                type={'submit'}
                className={`${styles.submit} ${loading ? styles.button__loading : ''}`}
                onClick={submitHandler}
                disabled={!activeBtn}
              >
                <span className={styles.button_text}>Submit</span>
                <span className={styles.button_divider}>/</span>
                <span className={styles.button_text}>إرسال</span>
              </button>
            </div>
          )}
      </div>
    </div>
  )
}

const mapStateToProps = (state:any) => {
  return {store: state}
}
const mapDispatchToProps = {
  uploadFileForSubject,
  getUser,
  getSubject
}

export const RecordingPage = connect(
  mapStateToProps, mapDispatchToProps
)(RecordingStep)